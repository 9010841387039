import { template as template_b9f70ca0f43c408c898b623ac3592f4d } from "@ember/template-compiler";
const FKLabel = template_b9f70ca0f43c408c898b623ac3592f4d(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
