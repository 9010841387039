import { template as template_48505d2a0e7447ae9622152d241d0f7d } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const BackButton = template_48505d2a0e7447ae9622152d241d0f7d(`
  <DButton
    @action={{@onGoBack}}
    @label="topic.timeline.back"
    @title="topic.timeline.back_description"
    class="btn-primary btn-small back-button"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default BackButton;
