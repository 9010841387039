import { template as template_ba99e25936e848dbbfd03d3c23479234 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ViewsCell = template_ba99e25936e848dbbfd03d3c23479234(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="views"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="views"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ViewsCell;
