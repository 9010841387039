import { template as template_3828ae9ad5d9467c85fa77dd5f8d9f29 } from "@ember/template-compiler";
const FKText = template_3828ae9ad5d9467c85fa77dd5f8d9f29(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
